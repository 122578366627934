import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
} from 'reactstrap';

const ContentMain = ({ data }) => (
  <>
    <Col md={12}>
      <Card>
        <CardBody>
          <div dangerouslySetInnerHTML={{ __html: data.html_content }} />
        </CardBody>
      </Card>
    </Col>
  </>
);

ContentMain.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    html_content: PropTypes.string,
  }).isRequired,
};

export default ContentMain;
