const getFrontendRole = (roleKey) => {
  switch (roleKey) {
    case 'ROLE_ADMIN': return 'Admin';
    case 'ROLE_PUBLISHER': return 'Közzétevő';
    case 'ROLE_MHFE': return 'MHFÉ';
    case 'ROLE_INTERMEDIARY': return 'Reklámközvetítő';
    case 'ROLE_ADVERTISER': return 'Reklámozó';
    case 'ROLE_CUSTOMER': return 'Vásárló';
    default: return '';
  }
};

const getBackendRole = (roleKey) => {
  switch (roleKey) {
    case 'Admin': return 'ROLE_ADMIN';
    case 'Közzétevő': return 'ROLE_PUBLISHER';
    case 'MHFÉ': return 'ROLE_MHFE';
    case 'Reklámközvetítő': return 'ROLE_INTERMEDIARY';
    case 'Reklámozó': return 'ROLE_ADVERTISER';
    default: return '';
  }
};

const getHomePage = (roleKey) => {
  switch (roleKey) {
    case 'ROLE_ADMIN': return '/accounts';
    default: return '';
  }
};

const getRoleOptions = (except = []) => {
  const options = [
    // { value: '', label: 'Kérem válasszon' },
    { value: 'ROLE_CUSTOMER', label: 'Vásárló' },
    { value: 'ROLE_ADMIN', label: 'Admin' },
    // { value: 'ROLE_PUBLISHER', label: 'Közzétevő' },
    // { value: 'ROLE_MHFE', label: 'MHFÉ' },
    // { value: 'ROLE_INTERMEDIARY', label: 'Reklámközvetítő' },
    // { value: 'ROLE_ADVERTISER', label: 'Reklámozó' },
  ];

  return options.filter((option) => !except.includes(option.value));
};

export {
  getBackendRole, getFrontendRole, getHomePage, getRoleOptions,
};
