import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AtIcon from 'mdi-react/AtIcon';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { login } from '../../../../redux/actions/authActions';
import RenderField from '../../../../shared/components/form/RenderField';

class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.state = {
      showPassword: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    const {
      handleSubmit, isAuthenticated, loggedIn,
    } = this.props;
    const { showPassword } = this.state;

    if (isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <form className="form" onSubmit={handleSubmit(loggedIn)}>
        <div className="form__form-group">
          <span className="form__form-group-label">{this.t('email')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AtIcon />
            </div>
            <Field
              name="email"
              component={RenderField}
              type="text"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">{this.t('password')}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={RenderField}
              type={showPassword ? 'text' : 'password'}
              placeholder="Jelszó"
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={(e) => this.showPassword(e)}
            >
              <EyeIcon />
            </button>
          </div>
          <div className="account__forgot-password">
            <a href="/forgot">{this.t('forgotPassword')}</a>
          </div>
        </div>
        <div className="account__btns">
          <button className="btn btn-success account__btn" type="submit">{this.t('login')}</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loggedIn: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { loggedIn: login })(reduxForm({
  form: 'log_in_form',
})(withTranslation('login')(LogInForm)));
