import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const TopbarNavCms = () => {
  const { t } = useTranslation();

  return (
    <NavLink
      to="/contents"
      className="topbar__nav-link"
    >
      <p className="topbar__link-title">
        {t('menu:cms:contents')}
      </p>
    </NavLink>
  );
};

export default TopbarNavCms;
