import React from 'react';

import { Card, CardBody, Col } from 'reactstrap';
import DeviceStatusCreateForm from './DeviceStatusCreateForm';

const DeviceStatusCreateCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <DeviceStatusCreateForm initialValues={{ severity: 1 }} onSubmit />
      </CardBody>
    </Card>
  </Col>
);

export default DeviceStatusCreateCard;
