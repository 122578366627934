import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import TransactionCard from './components/TransactionCard';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';

const TransactionPage = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [transaction, setTransaction] = useState({});
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadTransaction = useCallback(async () => {
    onShowSpinner();
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/transactions/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setTransaction(res.data);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadTransaction();
    }
  }, [isAuthenticated, loadTransaction]);

  return (
    <PageHandler statusCode={statusCode}>
      <form className="form">
        <Container>
          <div className="transaction-page">
            <Row>
              <Col md={12}>
                <h3 className="page-title">{t('transaction:view')}</h3>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <TransactionCard data={transaction} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <BackButton path="/transactions" />
        </Container>
      </form>
    </PageHandler>
  );
};

const transactionPageForm = reduxForm({ form: 'transaction_page_form' })(TransactionPage);
export default transactionPageForm;
