import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ForbidIcon from 'mdi-react/ForbidIcon';

const Forbidden403 = () => {
  const { t } = useTranslation();

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="default-pages">
            <div className="default-pages__icon">
              <ForbidIcon className="default-pages__svg danger" />
            </div>
            <h3 className="default-pages__title danger">{t('common:accessDenied')}</h3>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Forbidden403;
