import axios from 'axios';
import queryString from 'query-string';
import { reset } from 'redux-form';
import { setAuthToken } from '../../shared/utility/APIUtils';
import { setAlert } from './alertActions';
import throwError from '../../shared/utility/errorHandler';

const uri = `${process.env.REACT_APP_TICKET_API_URL}/api`;

export const AUTH_LOADED = 'AUTH_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const LOGOUT = 'LOGOUT';

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });

  window.location.replace('/login');
};

export const checkAuthTimeout = (expirationTime) => (dispatch) => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime);
};

export const loadAuth = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);

    try {
      const res = await axios.get(`${uri}/auth`);
      const { data } = res;

      if (!data.roles.includes('ROLE_ADMIN')) {
        dispatch({
          type: AUTH_ERROR,
        });
        return;
      }

      dispatch({
        type: AUTH_LOADED,
        payload: data,
      });

      const expiredIn = (data.exp - data.iat) * 1000;
      dispatch(checkAuthTimeout(expiredIn));
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (values) => async (dispatch) => {
  const body = JSON.stringify(values);
  try {
    const res = await axios.post(`${uri}/login_check`, body);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadAuth());
    window.location.replace('/accounts');
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });

    switch (err.response.status) {
      case 401:
        dispatch(setAlert(err.response.data.detail, 'danger'));
        break;
      default:
        dispatch(setAlert('Sikertelen belépés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const forgotPassword = (values) => async (dispatch) => {
  const body = JSON.stringify(values);

  try {
    const res = await axios.post(`${uri}/auth/forgot`, body);

    dispatch({
      type: FORGOT_SUCCESS,
    });
    dispatch(reset('forgot_form'));
    dispatch(setAlert(res.data.message, 'info'));
  } catch (err) {
    dispatch({
      type: FORGOT_FAIL,
    });

    switch (err.response.status) {
      default:
        dispatch(setAlert('Sikertelen küldés!', 'danger'));
        break;
    }

    throwError(err.response.data);
  }
};

export const resetPassword = (values) => async (dispatch) => {
  const query = queryString.parse(window.location.search);
  const path = `${uri}/auth/reset?reset_token=${query.reset_token}`;
  const body = JSON.stringify(values);

  try {
    const res = await axios.post(path, body);

    dispatch({
      type: RESET_SUCCESS,
    });
    dispatch(reset('reset_form'));
    dispatch(setAlert(res.data.message, 'success'));
  } catch (err) {
    dispatch({
      type: RESET_FAIL,
    });
    dispatch(reset('reset_form'));

    switch (err.response.status) {
      default:
        dispatch(setAlert('Sikertelen mentés!', 'danger'));
        break;
    }

    if (err.response.data.type === 'Link Error') {
      dispatch(setAlert('Your password reset link has expired, please request a new one.', 'danger'));
    }

    throwError(err.response.data);
  }
};
