import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import BackButton from './BackButton';

const FormButtonToolbar = ({ form, path, t }) => (
  <ButtonToolbar className="form__button-toolbar">
    <button className="btn btn-success" type="submit">
      {t(form === 'edit' ? 'common:buttons:modify' : 'common:buttons:save')}
    </button>
    <BackButton isCancel path={path} />
  </ButtonToolbar>
);

FormButtonToolbar.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
  t: PropTypes.func.isRequired,
};

FormButtonToolbar.defaultProps = {
  form: '',
  path: '/',
};

export default withTranslation()(FormButtonToolbar);
