import axios from 'axios';
import i18next from 'i18next';
import { setAlert } from './alertActions';
import { hideSpinner, showSpinner } from './spinnerActions';

export const SET_LOCATION_LIST = 'SET_LOCATION_LIST';

export const setLocationList = (locations) => ({
  type: SET_LOCATION_LIST,
  payload: locations,
});

export const getLocationList = () => async (dispatch) => {
  dispatch(showSpinner());
  try {
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/locations`;
    const { data: { list: fetchedLocations = [] } } = await axios.get(path);
    dispatch(setLocationList(fetchedLocations));
  } catch (err) {
    dispatch(setAlert(i18next.t('location:fetchFailed'), 'danger'));
  }
  dispatch(hideSpinner());
};
