import {
  SET_LOCATION_FROM,
  SET_LOCATION_TO,
} from '../actions/geoActions';

const initialState = {
  locationFrom: null,
  locationTo: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCATION_FROM:
      return {
        ...state,
        locationFrom: payload,
      };
    case SET_LOCATION_TO:
      return {
        ...state,
        locationTo: payload,
      };
    default:
      return state;
  }
};
