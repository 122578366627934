import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const BackButton = ({ isCancel, path, t }) => {
  const history = useHistory();
  return (
    <button
      className="btn btn-secondary"
      type="button"
      onClick={() => {
        if (history.length <= 2) {
          // use the path when no browsing history is available
          window.location.replace(path);
          return;
        }
        history.goBack();
      }}
    >
      { t(isCancel ? 'common:buttons:cancel' : 'common:buttons:back') }
    </button>
  );
};

BackButton.defaultProps = {
  isCancel: false,
};

BackButton.propTypes = {
  path: PropTypes.string.isRequired,
  isCancel: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(BackButton);
