import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  CardBody, Col, Container, Row,
} from 'reactstrap';
import { format } from 'date-fns';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import BackButton from '../../../shared/components/form/BackButton';
import PageHandler from '../../../shared/components/PageHandler';
import { getFrontendRole } from '../../../shared/utility/RoleHelper';

const AccountPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [statusCode, setStatusCode] = useState(null);
  const [userData, setUserData] = useState({});

  const loadAccount = useCallback(async () => {
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/users/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setUserData({
          ...res.data,
        });
      }).catch((err) => {
        setStatusCode(err.response.status);
      });
  }, [id]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadAccount();
    }
  }, [isAuthenticated, loadAccount]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="account-page">
          <Row>
            <Col md={12}>
              <h3 className="page-title">View User</h3>
            </Col>
          </Row>
          <div>
            <CardBody>
              <div className="device-card">
                <Row>
                  <Col md={12} lg={12}>
                    <div className="device-card__info">
                      <Row>
                        <Col md={12}>
                          <p>{t('account:email')}</p>
                          <span>{userData?.email}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <p>{t('account:firstName')}</p>
                          <span>{userData?.first_name}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <p>{t('account:lastName')}</p>
                          <span>{userData?.last_name}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <p>{t('account:phone')}</p>
                          <span>{userData?.phone_number}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <p>{t('account:role')}</p>
                          {(userData?.roles?.length > 0) && (
                            <span>{getFrontendRole(userData?.roles[0])}</span>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        {(userData?.birth_date) && (
                          <Col md={12}>
                            <p>{t('account:birthDate')}</p>
                            <span>{format(new Date(userData.birth_date), 'yyyy-MM-dd')}</span>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </div>
        </div>
        <BackButton path="/accounts" />
      </Container>
    </PageHandler>
  );
};

export default AccountPage;
