import React, { useCallback } from 'react';
import axios from 'axios';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import AccountForm from '../../components/AccountForm';

const AccountCreateForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const saveAccount = (async (account) => {
    onShowSpinner();
    const clone = {
      ...account,
    };

    if (account.company === null) {
      delete clone.company;
    }
    delete clone.company_new;
    delete clone.is_new_company;

    const body = JSON.stringify(clone);
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/users?mail=1`;
    try {
      await axios.post(path, body);
      dispatch(setAlert('Sikeres felvétel!', 'success'));
      window.location.replace('/accounts');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert('Sikertelen felvétel!', 'danger'));
          break;
      }
      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(saveAccount)}>
      <AccountForm form="create" />
    </form>
  );
};

AccountCreateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'account_create_form',
  enableReinitialize: true,
})(AccountCreateForm);

export default FormDecoratedComponent;
