import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopbarWithNavigation from './TopbarWithNavigation';
import SidebarMobile from './sidebar_mobile/SidebarMobile';
import { changeMobileSidebarVisibility } from '../../redux/actions/sidebarActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';

class Layout extends Component {
  changeMobileSidebarVisibility = () => {
    const { dispatch } = this.props;
    dispatch(changeMobileSidebarVisibility());
  };

  render() {
    const { sidebar/* , theme */ } = this.props;

    return (
      <div className="layout layout--top-navigation">
        <TopbarWithNavigation
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
        <SidebarMobile
          sidebar={sidebar}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
  // theme: ThemeProps.isRequired,
};

export default withRouter(connect((state) => ({
  sidebar: state.sidebar,
  theme: state.theme,
}))(Layout));
