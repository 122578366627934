import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ContentEditCard from './components/ContentEditCard';

const ContentEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const content = useSelector((state) => {
    const contentObject = state.cms.content.contents.find((element) => element.id === +id);
    return contentObject || {};
  });

  return (
    <Container>
      <div className="content-edit">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('content:edit')}</h3>
          </Col>
        </Row>
        <Row>
          <ContentEditCard data={content} />
        </Row>
      </div>
    </Container>
  );
};

export default ContentEdit;
