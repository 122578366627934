import React from 'react';
import LinkVariantOffIcon from 'mdi-react/LinkVariantOffIcon';
import PropTypes from 'prop-types';
import { TableCell, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import EditIcon from 'mdi-react/EditIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import CartOffIcon from 'mdi-react/CartOffIcon';
import CashIcon from 'mdi-react/CurrencyUsdIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import PrintIcon from 'mdi-react/PrinterIcon';

const TableRowActions = ({
  rowId, actions, actionCallbacks, actionLinks, rowActionDeps, t,
}) => {
  const defaultLinks = {
    show: `${window.location.pathname}/show/${rowId}`,
    edit: `${window.location.pathname}/edit/${rowId}`,
    cancel: `${window.location.pathname}/cancel/${rowId}`,
    accept: `${window.location.pathname}${window.location.search}`,
    reject: `${window.location.pathname}${window.location.search}`,
    delete: `${window.location.pathname}${window.location.search}`,
    unlink: `${window.location.pathname}${window.location.search}`,
  };

  const getDynamicLink = (type) => {
    if (type in actionLinks && typeof actionLinks[type] === 'function') {
      return actionLinks[type](rowId);
    }
    return defaultLinks[type];
  };

  return (
    <TableCell key={`operations-${rowId}`} className="material-table__cell--operations">
      {actions.includes('show') && (
      <Tooltip title={t('common:actions:view')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" to={getDynamicLink('show')}><EyeIcon /></Link>
      </Tooltip>
      )}
      {actions.includes('edit') && (
      <Tooltip title={t('common:actions:edit')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" to={getDynamicLink('edit')}><EditIcon /></Link>
      </Tooltip>
      )}
      {actions.includes('accept') && (
      <Tooltip title={t('common:actions:accept')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" onClick={() => actionCallbacks.update(rowId)} to={getDynamicLink('accept')}>
          <CheckIcon />
        </Link>
      </Tooltip>
      )}
      {actions.includes('reject') && (
      <Tooltip title={t('common:actions:reject')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" onClick={() => actionCallbacks.reject(rowId)} to={getDynamicLink('reject')}>
          <CloseIcon />
        </Link>
      </Tooltip>
      )}
      {actions.includes('delete') && (
      <Tooltip title={t('common:actions:delete')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" onClick={() => actionCallbacks.delete(rowId)} to={getDynamicLink('delete')}>
          <DeleteIcon />
        </Link>
      </Tooltip>
      )}
      {actions.includes('permission') && (
      <Tooltip title={t('common:actions:permission')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" to={`${window.location.pathname}/permission/${rowId}`}><KeyVariantIcon /></Link>
      </Tooltip>
      )}
      {actions.includes('modal') && (
      <Tooltip title={t('common:actions:details')} placement="bottom" arrow>
        <button className="material-table__cell__btn btn" onClick={() => actionCallbacks.modalOpen(rowId)} type="button">
          <EyeIcon />
        </button>
      </Tooltip>
      )}
      {actions.includes('print') && (
      <Tooltip title={t('common:actions:print')} placement="bottom" arrow>
        <button className="material-table__cell__btn btn" onClick={() => actionCallbacks.print(rowId)} type="button">
          <PrintIcon />
        </button>
      </Tooltip>
      )}
      {actions.includes('unlink') && (
      <Tooltip title={t('common:actions:unlink')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" onClick={() => actionCallbacks.unlink(rowId)} to={getDynamicLink('unlink')}>
          <LinkVariantOffIcon />
        </Link>
      </Tooltip>
      )}
      {actions.includes('payment') && Object.prototype.hasOwnProperty.call(rowActionDeps, 'paymentId') && (
      <Tooltip title={t('common:actions:paymentView')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" to={`payments/show/${rowActionDeps.paymentId}`}><CashIcon /></Link>
      </Tooltip>
      )}
      {actions.includes('cancel') && Object.prototype.hasOwnProperty.call(rowActionDeps, 'isCancelled') && !rowActionDeps.isCancelled && (
      <Tooltip title={t('common:actions:cancelBooking')} placement="bottom" arrow>
        <Link className="material-table__cell__btn btn" to={getDynamicLink('cancel')}><CartOffIcon /></Link>
      </Tooltip>
      )}
    </TableCell>
  );
};

TableRowActions.propTypes = {
  rowId: PropTypes.number.isRequired,
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  actionCallbacks: PropTypes.objectOf(PropTypes.func).isRequired,
  actionLinks: PropTypes.objectOf(PropTypes.func).isRequired,
  rowActionDeps: PropTypes.shape({
    paymentId: PropTypes.number,
    bookingId: PropTypes.number,
    isCancelled: PropTypes.bool,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TableRowActions);
