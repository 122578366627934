import {
  SHOW_SPINNER,
  HIDE_SPINNER,
} from '../actions/spinnerActions';

const initialState = {
  spinnerIsVisible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return { spinnerIsVisible: true };
    case HIDE_SPINNER:
      return { spinnerIsVisible: false };
    default:
      return state;
  }
};
