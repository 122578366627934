import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import hu from 'date-fns/locale/hu';

registerLocale('hu', hu);

const DatePickerField = ({
  value, name, dateFormat, onChange, showTimeSelect, timeFormat, timeIntervals, disabled, minDate,
}) => {
  const [dateStringFormat, setDateStringFormat] = useState(dateFormat);

  const handleChange = (date) => {
    if (date !== null) {
      const dateString = format(new Date(date), dateStringFormat);
      onChange(dateString);
    } else {
      onChange(date);
    }
  };

  useEffect(() => {
    if (showTimeSelect === true) {
      setDateStringFormat(`${dateFormat} ${timeFormat}`);
    }
  }, []);

  return (
    <div className="date-picker">
      <DatePicker
        locale="hu"
        name={name}
        className="form__form-group-datepicker"
        selected={(value === '' || value === '1970-01-01') ? null : new Date(value)}
        onChange={handleChange}
        showTimeSelect={showTimeSelect}
        dateFormat={dateStringFormat}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        dropDownMode="select"
        isClearable={disabled !== true}
        autoComplete="off"
        disabled={disabled}
        minDate={minDate}
      />
    </div>
  );
};

const renderDatePickerField = ({
  input, meta, dateFormat, showTimeSelect, timeFormat, timeIntervals, disabled, minDate,
}) => (
  <div className="form__form-group-input-wrap">
    <DatePickerField
      {...input}
      dateFormat={dateFormat}
      showTimeSelect={showTimeSelect}
      timeFormat={timeFormat}
      timeIntervals={timeIntervals}
      disabled={disabled}
      minDate={minDate}
    />
    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
  </div>
);

DatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  showTimeSelect: PropTypes.bool.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeIntervals: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
};

DatePickerField.defaultProps = {
  value: null,
  disabled: false,
  minDate: null,
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  dateFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
};

renderDatePickerField.defaultProps = {
  meta: null,
  dateFormat: 'yyyy-MM-dd',
  showTimeSelect: false,
  timeFormat: 'HH:mm',
  timeIntervals: 60,
  disabled: false,
  minDate: null,
};

export default renderDatePickerField;
