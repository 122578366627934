import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Col, Container, Row, Card, CardBody,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { format } from 'date-fns';
import Paper from '@material-ui/core/Paper';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';
import RefreshForm from './components/RefreshForm';
import Can from '../../shared/authorization/Can';
import StatusInfoCard from './components/StatusInfoCard';
import LiveFilterCard from './Filter/LiveFilterCard';

const LiveList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const operations = [];

  const colorForSeverity = [
    {
      id: 0,
      name: 'Ok',
    },
    {
      id: 1,
      name: 'Information',
    },
    {
      id: 2,
      name: 'Warning',
    },
    {
      id: 3,
      name: 'Error',
    },
  ];

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/devices${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          last_sign_of_life: (row.last_sign_of_life === null) ? '' : format(new Date(row.last_sign_of_life), 'yyyy-MM-dd HH:mm'),
          device_statuses: <div
            dangerouslySetInnerHTML={{ __html: row.device_statuses }}
            className={`severity-${row.severity}`}
          />,
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const close = () => {
    setSelectedDevice(null);
    setIsOpen(false);
  };

  const showModal = (id) => {
    const item = rows.find((row) => row.id === id);
    setSelectedDevice(item);
    setIsOpen(true);
  };

  const actionCallbacks = {
    modalOpen: showModal,
  };

  const columns = [
    {
      id: 'id', label: t('live:deviceId'),
    },
    {
      id: 'name', label: t('live:deviceName'),
    },
    {
      id: 'vpn_ip', label: t('live:vpnIp'),
    },
    {
      id: 'last_sign_of_life', label: t('live:lastSignOfLife'),
    },
    {
      id: 'device_statuses', label: t('live:deviceStatuses'), disableSort: true,
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <Modal returnFocusAfterClose={false} isOpen={isOpen} className="status-info-modal">
          <ModalHeader toggle={close} className="modal__header">
            { selectedDevice !== null && (selectedDevice.name) }
          </ModalHeader>
          <ModalBody className="modal__body">
            { selectedDevice !== null && <StatusInfoCard id={selectedDevice.id} /> }
          </ModalBody>
        </Modal>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('live:live')}</h3>
          </Col>
        </Row>
        <Row>
          <RefreshForm />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Col md={12}>
                  <Row>
                    {colorForSeverity.map((item) => (
                      <Col
                        md={3}
                        key={item.id}
                      >
                        <Paper>
                          <div className={`severity-${item.name.toLowerCase()}__card`}>
                            {item.name}
                          </div>
                        </Paper>
                      </Col>
                    )) }
                  </Row>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Can
            perform="live:modal"
            yes={() => {
              operations.push('modal');
              return null;
            }}
          />
          <Col>
            <LiveFilterCard refreshCallback={fetchData} />
          </Col>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
            actionCallbacks={actionCallbacks}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default LiveList;
