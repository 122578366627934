import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const NotFound404 = () => {
  const { t } = useTranslation();

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="default-pages">
            <div className="default-pages__icon">
              <p>404</p>
            </div>
            <h3 className="default-pages__title">{t('common:notFound')}</h3>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default NotFound404;
