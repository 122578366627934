/* eslint-disable no-underscore-dangle */
import { SubmissionError } from 'redux-form';

const throwError = (errors) => {
  const errorObject = {};
  const { violations } = errors;
  if (violations) {
    violations.map((violation) => {
      if (violation.propertyPath.match(/\[[0-9]+\]/g)) {
        const from = violation.propertyPath.indexOf('[') + 1;
        const to = violation.propertyPath.indexOf(']');
        const index = violation.propertyPath.slice(from, to);

        const n = violation.propertyPath.indexOf('.');
        const formSection = violation.propertyPath.slice(0, violation.propertyPath.indexOf('['));
        const property = violation.propertyPath.slice(n + 1, violation.propertyPath.length);

        let insert = {};

        if (errorObject[formSection]) {
          errorObject[formSection] = [...errorObject[formSection]];
          if (errorObject[formSection][+index]) {
            insert = { ...errorObject[formSection][+index] };
          }
        } else {
          errorObject[formSection] = [];
        }

        insert[property] = violation.title;
        errorObject[formSection][+index] = insert;
      } else if (violation.propertyPath.indexOf('.') > -1) {
        const n = violation.propertyPath.indexOf('.');
        const formSection = violation.propertyPath.slice(0, n);
        const property = violation.propertyPath.slice(n + 1, violation.propertyPath.length);
        errorObject[formSection] = { ...errorObject[formSection] };
        errorObject[formSection][property] = violation.title;
      } else if (violation.propertyPath.match(/\[[a-zA-Z]+\]/g)) {
        const from = violation.propertyPath.indexOf('[') + 1;
        const to = violation.propertyPath.indexOf(']');
        const property = violation.propertyPath.slice(from, to);
        errorObject[property] = violation.title;
      } else {
        switch (violation.propertyPath) {
          case 'images':
            if (errorObject[violation.propertyPath] === undefined) {
              errorObject[violation.propertyPath] = `${violation.parameters['{{ name }}']}: ${violation.title}`;
            } else {
              errorObject[violation.propertyPath] += `${violation.parameters['{{ name }}']}: ${violation.title}`;
            }
            break;
          default:
            errorObject[violation.propertyPath] = violation.title;
            break;
        }
      }
      return errorObject;
    });
  } else {
    errorObject._error = errors.detail;
  }
  throw new SubmissionError(errorObject);
};

export default throwError;
