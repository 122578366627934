import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import { format } from 'date-fns';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';
import DeviceStatusLogFilterCard from './Filter/DeviceStatusLogFilterCard';

const DeviceStatusLogList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/device-status-logs${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          duration: (row.duration === null) ? '' : String(row.duration).concat(' min'),
          end_date: (row.end_date === null) ? '' : format(new Date(row.end_date), 'yyyy-MM-dd HH:mm'),
          created_at: format(new Date(row.created_at), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'device', label: t('deviceStatusLog:device'),
    },
    {
      id: 'device_status', label: t('deviceStatusLog:deviceStatus'),
    },
    {
      id: 'severity', label: t('deviceStatusLog:severity'),
    },
    {
      id: 'note', label: t('deviceStatusLog:note'),
    },
    {
      id: 'created_at', label: t('deviceStatusLog:start'),
    },
    {
      id: 'end_date', label: t('deviceStatusLog:end'),
    },
    {
      id: 'duration', label: t('deviceStatusLog:duration'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('deviceStatusLog:list')}</h3>
          </Col>
        </Row>
        <Row>
          <Col><DeviceStatusLogFilterCard refreshCallback={fetchData} /></Col>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default DeviceStatusLogList;
