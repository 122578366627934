import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import { setAlert } from '../../../../redux/actions/alertActions';
import { showSpinner, hideSpinner } from '../../../../redux/actions/spinnerActions';
import AccountForm from '../../components/AccountForm';

const AccountEditForm = ({ handleSubmit }) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const editAccount = (async (account) => {
    onShowSpinner();
    const clone = {
      ...account,
    };
    delete clone.roles;

    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/users/${id}`;
    const body = JSON.stringify(clone);

    try {
      await axios.put(path, body);
      dispatch(setAlert('Sikeres módosítás!', 'success'));
      window.location.replace('/accounts');
    } catch (err) {
      onHideSpinner();
      switch (err.response.status) {
        default:
          dispatch(setAlert('Sikertelen módosítás!', 'danger'));
          break;
      }

      throwError(err.response.data);
    }
    onHideSpinner();
  });

  return (
    <form className="form" onSubmit={handleSubmit(editAccount)}>
      <AccountForm form="edit" />
    </form>
  );
};

AccountEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'account_edit_form',
  enableReinitialize: true,
})(AccountEditForm);

export default FormDecoratedComponent;
