/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Card, CardBody, Col } from 'reactstrap';
import {
  Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Checkbox,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import TablePaginationActions from './TablePaginationActions';
import MaterialTableHead from './MaterialTableHead';
import setUrlQuery from '../../utility/urlModify';
import TableRowActions from './TableRowActions';
import TableToolbar from './TableToolbar';
import { boolToString } from '../../utility/formatter';

const MaterialTable = (props) => {
  const {
    rows,
    columns,
    total,
    checkboxSelection,
    toolbarActions,
    toolbarActionDone,
    callback,
    actionCallbacks,
    actionLinks,
    operations,
    actionRoles,
    dependencyCells,
    t,
  } = props;
  const query = queryString.parse(window.location.search);
  const [order, setOrder] = useState(typeof query.order !== 'undefined' ? query.order : 'desc');
  const [orderBy, setOrderBy] = useState(typeof query.orderBy !== 'undefined' ? query.orderBy : 'id');
  const [selected, setSelected] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [page, setPage] = useState(typeof query.page !== 'undefined' ? parseInt(query.page, 10) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(typeof query.rowsPerPage !== 'undefined' ? parseInt(query.rowsPerPage, 10) : 10);

  useEffect(() => {
    const currentRows = rows.map((n) => n.id);
    const currentSelecteds = selected.filter((el) => currentRows.includes(el));
    setCurrentSelected(currentSelecteds);
  }, [rows]);

  useEffect(() => {
    setUrlQuery('order', order);
    setUrlQuery('orderBy', orderBy);
    setUrlQuery('page', page);
    setUrlQuery('rowsPerPage', rowsPerPage);
  }, []);

  useEffect(() => {
    if (typeof query.order !== 'undefined' && query.order !== order) setOrder(query.order);
    if (typeof query.orderBy !== 'undefined' && query.orderBy !== orderBy) setOrderBy(query.orderBy);
    if (typeof query.page !== 'undefined' && parseInt(query.page, 10) !== page) setPage(parseInt(query.page, 10));
    if (typeof query.rowsPerPage !== 'undefined' && parseInt(query.rowsPerPage, 10) !== rowsPerPage) setPage(parseInt(query.rowsPerPage, 10));
  }, [query]);

  const headCells = columns.map((column) => {
    const newColumn = { ...column };
    if (!Object.prototype.hasOwnProperty.call(newColumn, 'numeric')) {
      newColumn.numeric = false;
    }
    if (!Object.prototype.hasOwnProperty.call(newColumn, 'disablePadding')) {
      newColumn.disablePadding = false;
    }
    if (!Object.prototype.hasOwnProperty.call(newColumn, 'visibility')) {
      newColumn.visibility = true;
    }
    if (!Object.prototype.hasOwnProperty.call(newColumn, 'disableSort')) {
      newColumn.disableSort = false;
    }
    return newColumn;
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setUrlQuery('order', isAsc ? 'desc' : 'asc');
    setUrlQuery('orderBy', property);
    callback();
  };

  const handleDeleteSelected = () => {
    setCurrentSelected([]);
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    const currentRows = rows.map((n) => n.id);
    if (event.target.checked) {
      setCurrentSelected(currentRows);
      setSelected([...new Set([...selected, ...currentRows])]);
      return;
    }

    setCurrentSelected([]);
    const newSelecteds = selected.filter((el) => !currentRows.includes(el));
    setSelected(newSelecteds);
  };

  const getIds = (selectedIds, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    const newSelected = [];

    if (selectedIndex === -1) {
      return newSelected.concat(selectedIds, id);
    }

    if (selectedIndex === 0) {
      return newSelected.concat(selectedIds.slice(1));
    }

    if (selectedIndex === selectedIds.length - 1) {
      return newSelected.concat(selectedIds.slice(0, -1));
    }

    if (selectedIndex > 0) {
      return newSelected.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1),
      );
    }

    return newSelected;
  };

  const handleClick = (event, id) => {
    setCurrentSelected(getIds(currentSelected, id));
    setSelected(getIds(selected, id));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUrlQuery('page', newPage);
    callback();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUrlQuery('page', 0);
    setUrlQuery('rowsPerPage', event.target.value);
    callback();
  };

  const isSelected = (id) => currentSelected.indexOf(id) !== -1;

  const setRowActionDeps = (row, cells) => {
    const depObject = {};
    if (Object.entries(row).length !== 0 && cells.length !== 0) {
      cells.map((cell) => {
        depObject[cell] = row[cell];
        return depObject;
      });
    }
    return depObject;
  };

  useEffect(() => {
    handleDeleteSelected();
  }, [toolbarActionDone]);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          {checkboxSelection && (
            <TableToolbar
              selected={selected}
              handleDeleteSelected={handleDeleteSelected}
              actions={toolbarActions}
            />
          )}
          <TableContainer className="material-table__wrap">
            <Table className="material-table">
              <MaterialTableHead
                headCells={headCells}
                numSelected={currentSelected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                checkboxSelection={checkboxSelection}
                operations={operations}
              />
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `material-table-checkbox-${index}`;
                  let filteredOperations = operations;

                  if (operations.length > 0 && typeof actionRoles !== 'undefined' && actionRoles.length > 0) {
                    const list = actionRoles.filter(
                      (actionRole) => actionRole.id === row.id,
                    ).map(
                      (actionRole) => actionRole.actions,
                    ).flat();

                    if (typeof list !== 'undefined') {
                      filteredOperations = list.filter((x) => operations.includes(x));
                    }
                  }

                  return (
                    <TableRow
                      className="material-table__row"
                      tabIndex={-1}
                      key={row.id}
                      onClick={checkboxSelection ? (event) => handleClick(event, row.id) : null}
                      role={checkboxSelection ? 'checkbox' : 'row'}
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      {checkboxSelection && (
                        <TableCell className="material-table__cell" padding="checkbox">
                          <Checkbox
                            className="material-table__checkbox"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                      )}
                      {headCells.map((cell) => (
                        <TableCell key={`${cell.id}-${row.id}`} className="material-table__cell">
                          {cell.visibility === true && (boolToString(row[cell.id], t))}
                        </TableCell>
                      ))}
                      {operations.length > 0 && (
                        <TableRowActions
                          rowId={row.id}
                          actions={filteredOperations}
                          actionCallbacks={actionCallbacks}
                          actionLinks={actionLinks}
                          rowActionDeps={setRowActionDeps(row, dependencyCells)}
                        />
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="material-table__pagination">
            <TablePagination
              component="div"
              classes={{ root: 'tablePagination' }}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              labelRowsPerPage={t('rowsPerPage')}
              labelDisplayedRows={({ from, to }) => `${t('rows')} ${from}-${to} / ${total}`}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

MaterialTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  actionCallbacks: PropTypes.objectOf(PropTypes.func),
  actionLinks: PropTypes.objectOf(PropTypes.func),
  checkboxSelection: PropTypes.bool,
  toolbarActions: PropTypes.arrayOf(PropTypes.shape({})),
  toolbarActionDone: PropTypes.bool,
  operations: PropTypes.arrayOf(PropTypes.string),
  actionRoles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      actions: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  dependencyCells: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
};

MaterialTable.defaultProps = {
  checkboxSelection: false,
  toolbarActions: [],
  toolbarActionDone: false,
  operations: [],
  actionRoles: [],
  actionCallbacks: {},
  actionLinks: {},
  dependencyCells: [],
};

export default withTranslation('common')(MaterialTable);
