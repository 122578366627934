import React from 'react';
// hidden from menu because not in use
// import TopbarNavProductionsAndEvents from './TopbarNavProductionsAndEvents';
import TopbarNavSettingsAndOptions from './TopbarNavSettingsAndOptions';
import TopbarNavAllpay from './TopbarNavAllpay';
import TopbarNavCms from './TopbarNavCms';

const TopbarNav = () => (
  <nav className="topbar__nav">
    <TopbarNavSettingsAndOptions />
    <TopbarNavAllpay />
    <TopbarNavCms />
  </nav>
);

export default TopbarNav;
