import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import LinkVariantOffIcon from 'mdi-react/LinkVariantOffIcon';

const LinkErrorCard = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className="link-confirmation">
          <div className="link-confirmation__icon">
            <LinkVariantOffIcon className="link-confirmation__link" />
          </div>
          <h3 className="link-confirmation__title">Sajnáljuk de ez a link már nem használható!</h3>
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default LinkErrorCard;
