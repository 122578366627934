import rbacPermissions from './rbacPermissions';
import rbacRules from './rbacRules';
import { useRole, usePermissions } from '../utility/APIUtils';

const useCheck = (rules, action, data) => {
  const role = useRole();
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions) {
    // static rule "accounts" provide access to "accounts:create", "accounts:show", etc.
    if (staticPermissions.includes(action) || staticPermissions.includes(action.split(':')[0])) {
      return true;
    }
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const useCheckPermissions = (rules, action) => {
  const permissionsList = usePermissions();

  if (!action) {
    return true;
  }

  const permissions = [];
  Object.entries(rules).forEach((role) => {
    const [key, value] = role;
    if (permissionsList?.includes(key)) {
      permissions.push(value);
    }
  });

  if (permissions.length <= 0) {
    return false;
  }

  let allow = false;
  permissions.forEach((permission) => {
    const staticPermissions = permission.static;
    if (staticPermissions.includes(action) || staticPermissions.includes(action.split(':')[0])) {
      allow = true;
    }
  });

  return allow;
};

const Can = (props) => {
  const role = useCheck(rbacRules, props.perform, props.data);
  const permission = useCheckPermissions(rbacPermissions, props.permissionPerform);

  return role || permission ? props.yes() : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
