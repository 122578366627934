import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RenderField from '../../../shared/components/form/RenderField';

const AccountFilterForm = ({ handleSubmit, refreshCallback, t }) => {
  const history = useHistory();

  const sendFilter = (async (filter) => {
    const filterString = Object.entries(filter).map((item) => {
      const [key, val] = item;
      return `${key}=${val}`;
    }).join('&');

    history.push(`${window.location.pathname}?${filterString}`);
    refreshCallback();
  });

  return (
    <form className="filter" onSubmit={handleSubmit(sendFilter)}>
      <Col md={4}>
        <div className="form__form-group">
          <span className="form__form-group-label">{t('account:email')}</span>
          <div className="form__form-group-field">
            <Field
              name="email"
              component={RenderField}
              type="text"
              placeholder="E-mail"
            />
          </div>
        </div>
      </Col>
      <Col md={12} className="line-end">
        <button className="btn btn-success" type="submit">{t('common:filter')}</button>
      </Col>
    </form>
  );
};

AccountFilterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  refreshCallback: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'account_filter',
  enableReinitialize: true,
})(AccountFilterForm);

export default withTranslation()(FormDecoratedComponent);
