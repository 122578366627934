import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AccountCreateCard from './components/AccountCreateCard';

const AccountCreate = () => (
  <Container>
    <div className="account-create">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Create User</h3>
        </Col>
      </Row>
      <Row>
        <AccountCreateCard />
      </Row>
    </div>
  </Container>
);

export default AccountCreate;
