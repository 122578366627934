import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import ContentEditForm from './ContentEditForm';

const optimize = (object) => {
  if (Object.entries(object).length !== 0) {
    const clone = {
      ...object,
    };
    delete clone.id;
    if (typeof object.menu_item === 'number') {
      clone.menu_item_id = object.menu_item;
    } else if (object.menu_item?.id) {
      clone.menu_item_id = object.menu_item?.id;
    }
    delete clone.menu_item;
    delete clone.content_langs;
    delete clone.created_at;
    delete clone.updated_at;
    delete clone.deleted_at;
    return clone;
  }
  return object;
};

const ContentEditCard = ({ data }) => {
  const optimizeData = optimize(data);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="content-edit-page">
            <ContentEditForm
              id={data.id}
              initialValues={optimizeData}
              onSubmit
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

ContentEditCard.propTypes = {
  data: PropTypes.shape({
    content_langs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    id: PropTypes.number,
  }).isRequired,
};

export default ContentEditCard;
