import {
  SET_MENUPOINTS,
  SET_MENU,
  SET_MENU_ITEM,
  SET_MENU_ITEM_LIST,
} from '../actions/cmsMenuActions';

const initialState = {
  menupoints: [],
  menuItemList: [],
};

const handleSetMenu = (state, payload) => {
  const cloneMenupoints = [...state.menupoints];
  const existingMenuIndex = cloneMenupoints.findIndex((menupoint) => (
    menupoint.id === payload.id
  ));
  if (existingMenuIndex < 0) {
    cloneMenupoints.push(payload);
  } else {
    cloneMenupoints.splice(existingMenuIndex, 1, payload);
  }
  return {
    ...state,
    menupoints: cloneMenupoints,
  };
};

const handleSetMenuItem = (state, payload) => {
  const cloneMenupoints = [...state.menupoints];
  let menuItemIndex;
  const parentMenuIndex = cloneMenupoints.findIndex((menu) => {
    menuItemIndex = menu.menuItems.findIndex((menuItem) => menuItem.id === payload.id);
    return menuItemIndex >= 0;
  });
  if (parentMenuIndex >= 0) {
    cloneMenupoints[parentMenuIndex].menuItems[menuItemIndex] = payload;
  }
  return {
    ...state,
    menupoints: cloneMenupoints,
  };
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MENUPOINTS:
      return {
        ...state,
        menupoints: payload,
      };
    case SET_MENU_ITEM_LIST:
      return {
        ...state,
        menuItemList: payload,
      };
    case SET_MENU:
      return handleSetMenu(state, payload);
    case SET_MENU_ITEM:
      return handleSetMenuItem(state, payload);
    default:
      return state;
  }
};
