import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import SVG from 'react-inlinesvg';

const TopbarNavLink = ({
  activeClassName, title, icon, newLink, route, onClick,
}) => (
  <NavLink
    className="topbar__link"
    activeClassName={activeClassName}
    to={route}
    onClick={onClick}
  >
    {icon
      ? (
        <SVG
          src={icon}
          preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
          className="topbar__link-icon"
        />
      ) : ''}
    <p className="topbar__link-title">
      {title}
      {newLink ? <Badge className="topbar__link-badge"><span>New</span></Badge> : ''}
    </p>
  </NavLink>
);

TopbarNavLink.propTypes = {
  activeClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

TopbarNavLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '',
  onClick: () => {},
  activeClassName: 'topbar__link-active',
};

export default TopbarNavLink;
