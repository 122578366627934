import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import RenderField from '../../../shared/components/form/RenderField';
import convertToURL from '../../../shared/utility/filter';
import renderDatePickerField from '../../../shared/components/form/DatePicker';
import { setAlert } from '../../../redux/actions/alertActions';
import AutocompleteSelect from '../../../shared/components/form/AutocompleteSelect';

const DeviceCommandFilterForm = (props) => {
  const { handleSubmit, dispatch, refreshCallback } = props;
  const [deviceList, setDeviceList] = useState([]);
  const [commandList, setCommandList] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();
  const sendFilter = async (filter) => {
    const copy = { ...filter };
    const filterString = convertToURL(copy);
    history.push(`${window.location.pathname}?${filterString}`);
    refreshCallback();
  };

  const fetchSelects = useCallback(async () => {
    await axios.get(`${process.env.REACT_APP_TICKET_API_URL}/api/devices/options`)
      .then((res) => {
        const list = res.data.map(((device) => ({
          id: device.id,
          name: device.name,
        })));
        setDeviceList(list);
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      });

    await axios.get(`${process.env.REACT_APP_TICKET_API_URL}/api/commands`)
      .then((res) => {
        const list = res.data.map(((command) => ({
          id: command.id,
          name: command.name,
        })));
        setCommandList(list);
      }).catch(() => {
        dispatch(setAlert(t('common:error'), 'danger'));
      });
  }, [dispatch, t]);

  useEffect(() => {
    fetchSelects();
  }, [fetchSelects]);

  return (
    <form className="filter" onSubmit={handleSubmit(sendFilter)}>
      <Col md={12}>
        <Row>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('deviceCommand:device')}</span>
              <div className="form__form-group-field">
                <Field
                  name="device"
                  component={AutocompleteSelect}
                  options={deviceList}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('deviceCommand:command')}</span>
              <div className="form__form-group-field">
                <Field
                  name="command"
                  component={AutocompleteSelect}
                  options={commandList}
                />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('deviceCommand:parameter')}</span>
              <div className="form__form-group-field">
                <Field name="parameter" component={RenderField} type="text" />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('common:createdAt')}</span>
              <div className="form__form-group-field">
                <Field
                  name="created_at"
                  component={renderDatePickerField}
                  type="text"
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="form__form-group">
              <span className="form__form-group-label">{t('deviceCommand:executedAt')}</span>
              <div className="form__form-group-field">
                <Field
                  name="executed_at"
                  component={renderDatePickerField}
                  type="text"
                />
                <div className="form__form-group-icon">
                  <CalendarBlankIcon />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="line-end">
        <button className="btn btn-success" type="submit">
          {t('common:filter')}
        </button>
      </Col>
    </form>
  );
};

DeviceCommandFilterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  refreshCallback: PropTypes.func.isRequired,
};

const FormDecoratedComponent = reduxForm({
  form: 'device_status_log_filter',
  enableReinitialize: true,
})(DeviceCommandFilterForm);

export default FormDecoratedComponent;
