import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell, TableHead, TableRow, TableSortLabel, Checkbox,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const MaterialTableHead = (props) => {
  const {
    headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
    checkboxSelection, operations, t,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {checkboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              className={`material-table__checkbox ${numSelected === rowCount
              && 'material-table__checkbox--checked'}`}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            className="material-table__cell material-table__cell--sort"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.visibility === true && (
              (headCell.disableSort === false) ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className="material-table__sort-label"
                >
                  {headCell.label}
                </TableSortLabel>
              ) : headCell.label
            )}
          </TableCell>
        ))}
        {operations.length > 0 && (
        <TableCell
          className="material-table__cell material-table__cell--sort"
          key="operations"
          align="left"
          sortDirection={false}
        >
          {t('common:actions:label')}
        </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

MaterialTableHead.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  checkboxSelection: PropTypes.bool,
  operations: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func.isRequired,
};

MaterialTableHead.defaultProps = {
  checkboxSelection: false,
  operations: [],
};

export default withTranslation()(MaterialTableHead);
