import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const TopbarNavSettingsAndOptions = () => {
  const { t } = useTranslation();

  return (
    <NavLink
      to="/accounts"
      className="topbar__nav-link"
    >
      <p className="topbar__link-title">
        {t('menu:users')}
      </p>
    </NavLink>
  );
};

export default TopbarNavSettingsAndOptions;
