import React from 'react';

import ContentFilterForm from './ContentFilterForm';

const ContentFilterCard = () => {
  const data = () => {
    const urlList = {};

    window.location.search
      .slice(1)
      .split('&')
      .forEach((item) => {
        const [key, val] = item.split('=');
        if (val !== '') {
          urlList[key] = decodeURIComponent(val);
        }
      });

    return urlList;
  };

  return (
    <ContentFilterForm
      initialValues={data()}
    />
  );
};

export default ContentFilterCard;
