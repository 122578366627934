import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import RenderField from '../../../shared/components/form/RenderField';
import RenderSelectField from '../../../shared/components/form/RenderSelectField';
import FormButtonToolbar from '../../../shared/components/form/FormButtonToolbar';
import Auxiliary from '../../../shared/hoc/Auxiliary';
import { getRoleOptions } from '../../../shared/utility/RoleHelper';
import renderDatePickerField from '../../../shared/components/form/DatePicker';

const AccountForm = (props) => {
  const { t } = useTranslation();
  const {
    form,
    path,
  } = props;
  const isCreate = form === 'create';
  const isEdit = form === 'edit';

  const disabledProps = {};
  let except = [];
  if (isCreate) {
    except = ['ROLE_INTERMEDIARY', 'ROLE_ADVERTISER'];
  }
  if (isEdit) {
    disabledProps.disabled = true;
  }

  return (
    <Auxiliary>
      <div className="form__half">
        <div className="form-row">
          <Col md={12}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:email')}</span>
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:email')}
                  {...disabledProps}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:firstName')}</span>
              <div className="form__form-group-field">
                <Field
                  name="first_name"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:firstName')}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:lastName')}</span>
              <div className="form__form-group-field">
                <Field
                  name="last_name"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:lastName')}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:role')}</span>
              <div className="form__form-group-field">
                <Field
                  name="roles"
                  component={RenderSelectField}
                  options={getRoleOptions(except)}
                  {...disabledProps}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:phone')}</span>
              <div className="form__form-group-field">
                <Field
                  name="phone_number"
                  component={RenderField}
                  type="text"
                  placeholder={t('account:phone')}
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="form__form-group required">
              <span className="form__form-group-label">{t('account:birthDate')}</span>
              <div className="form__form-group-field">
                <Field
                  name="birth_date"
                  component={renderDatePickerField}
                  type="text"
                  placeholder={t('account:birthDate')}
                />
              </div>
            </div>
          </Col>
        </div>
        <div className="d-flex">
          <FormButtonToolbar form={form} path={path} />
        </div>
      </div>
    </Auxiliary>
  );
};

AccountForm.propTypes = {
  form: PropTypes.string,
  path: PropTypes.string,
};

AccountForm.defaultProps = {
  form: '',
  path: '/accounts',
};

export default AccountForm;
