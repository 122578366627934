export const SET_LOCATION_FROM = 'SET_LOCATION_FROM';
export const SET_LOCATION_TO = 'SET_LOCATION_TO';

export const setLocationFrom = (value) => (dispatch) => {
  dispatch({
    type: SET_LOCATION_FROM,
    payload: value,
  });
};

export const setLocationTo = (value) => (dispatch) => {
  dispatch({
    type: SET_LOCATION_TO,
    payload: value,
  });
};
