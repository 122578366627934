import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import throwError from '../../../../shared/utility/errorHandler';
import ContentForm from '../../components/ContentForm';
import { updateContent } from '../../../../redux/actions/cmsContentActions';

const ContentEditForm = ({ handleSubmit, id }) => {
  const dispatch = useDispatch();

  const editContent = (content) => {
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/contents/${id}`;
    const body = JSON.stringify(content);
    return dispatch(updateContent(path, body, true)).then(() => {
    }).catch((err) => {
      throwError(err.response?.data);
    });
  };

  return (
    <form className="form" onSubmit={handleSubmit(editContent)}>
      <ContentForm form="edit" />
    </form>
  );
};

ContentEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  id: PropTypes.number,
};

ContentEditForm.defaultProps = {
  id: null,
};

const FormDecoratedComponent = reduxForm({
  form: 'content_edit_form',
  enableReinitialize: true,
})(ContentEditForm);

export default FormDecoratedComponent;
