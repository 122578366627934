import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import MaterialTable from '../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../shared/utility/APIUtils';
import Can from '../../shared/authorization/Can';
import { showSpinner, hideSpinner } from '../../redux/actions/spinnerActions';
import PageHandler from '../../shared/components/PageHandler';

const DeviceStatusList = () => {
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);
  const operations = [];

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/device-statuses${window.location.search}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => ({
          ...row,
          created_at: format(new Date(row.created_at), 'yyyy-MM-dd HH:mm'),
          updated_at: format(new Date(row.updated_at), 'yyyy-MM-dd HH:mm'),
        })));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const columns = [
    {
      id: 'name', label: t('deviceStatus:statusName'),
    },
    {
      id: 'severity', label: t('deviceStatus:severityName'),
    },
    {
      id: 'created_at', label: t('common:createdAt'),
    },
    {
      id: 'updated_at', label: t('common:updatedAt'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <Row>
          <Col md={8}>
            <h3 className="page-title">{t('deviceStatus:list')}</h3>
          </Col>
          <Col md={4}>
            <Can
              perform="deviceStatuses:create"
              yes={() => (
                <Link
                  className="btn btn-success float-right"
                  to={`${window.location.pathname}/create`}
                >
                  {t('deviceStatus:create')}
                </Link>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Can
            perform="deviceStatuses:show"
            yes={() => { operations.push('show'); return null; }}
          />
          <Can
            perform="deviceStatuses:edit"
            yes={() => {
              operations.push('edit');
              return null;
            }}
          />
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            operations={operations}
          />
        </Row>
      </Container>
    </PageHandler>
  );
};

export default DeviceStatusList;
