import { SET_STATUS } from '../actions/statusActions';

const initialState = {
  statusCode: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS:
      return {
        ...state,
        statusCode: action.payload,
      };

    default:
      return { ...state };
  }
};
