import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';
import { removeAlert } from '../../redux/actions/alertActions';

const Alert = ({
  alerts,
  lockable,
  closeClick,
}) => alerts !== null && alerts.length > 0 && alerts.map((alert) => (
  <div key={alert.id} className={`alert alert-${alert.alertType}`}>
    <div className="alert__content">
      <p>{ alert.msg }</p>
      {lockable
        && (
          <button type="button" className="close" aria-label="Close" onClick={(e) => closeClick(e, alert.id)}>
            <CloseIcon />
          </button>
        )}
    </div>
  </div>
));

Alert.propTypes = {
  alerts: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  closeClick: (e, Id) => {
    e.preventDefault();
    dispatch(removeAlert(Id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
