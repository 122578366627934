import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import editorConfig from '../../utility/editorConfigEnum';

const RenderRichTextareaField = ({
  input, placeholder, disabled, meta: { touched, error }, config,
}) => {
  const init = {
    ...config,
    placeholder,
  };

  return (
    <div className="form__form-group-input-wrap">
      <Editor
        disabled={disabled}
        name={input.name}
        init={init}
        value={input.value}
        onEditorChange={input.onChange}
      />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );
};

RenderRichTextareaField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  config: PropTypes.shape({}),
};

RenderRichTextareaField.defaultProps = {
  placeholder: '',
  disabled: false,
  meta: null,
  config: editorConfig.DEFAULT,
};

export default RenderRichTextareaField;
