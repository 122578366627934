import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const setHeader = () => {
  axios.defaults.headers.common = {
    Accept: 'application/json',
  };
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.put['Content-Type'] = 'application/json';
};

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

const useAuthCheck = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (isAuthenticated !== false) {
      setRef(isAuthenticated);
    }
  }, [isAuthenticated]);

  return ref;
};

const useRole = () => useSelector((state) => state.auth.account && state.auth.account.roles[0]);

const usePermissions = () => useSelector((state) => state.auth.account?.permissions);

export {
  setHeader, setAuthToken, useAuthCheck, useRole, usePermissions,
};
