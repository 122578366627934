const permissions = {
  ALLOW_USER_VIEW: {
    static: ['accounts:show'],
  },
  ALLOW_USER_EDIT: {
    static: ['accounts:edit'],
  },
  ALLOW_USER_PERMISSION: {
    static: ['accounts:permission'],
  },
};

export default permissions;
