import queryString from 'query-string';

const setUrlQuery = (key, values) => {
  const currentUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const searchUrl = queryString.parse(window.location.search);

  Object.defineProperty(searchUrl, key, {
    value: values,
    writable: true,
    enumerable: true,
    configurable: true,
  });

  const nextSearchUrl = queryString.stringify(searchUrl);
  const newurl = `${currentUrl}?${nextSearchUrl}`;

  window.history.replaceState({ path: newurl }, '', newurl);
};

export default setUrlQuery;
