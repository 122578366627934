import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import {
  alertReducer,
  authReducer,
  sidebarReducer,
  themeReducer,
  spinnerReducer,
  geoReducer,
  productionReducer,
  cmsContentReducer,
  cmsMenuReducer,
  statusReducer,
} from '../../redux/reducers/index';

const cmsReducer = combineReducers({
  menu: cmsMenuReducer,
  content: cmsContentReducer,
});

const reducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  form: reduxFormReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  spinner: spinnerReducer,
  geo: geoReducer,
  production: productionReducer,
  cms: cmsReducer,
  status: statusReducer,
});

const middleware = [thunk];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
