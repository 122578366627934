import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../shared/components/PrivateRoute';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Alert from '../../shared/components/Alert';
import Spinner from '../../shared/components/Spinner';
import EmailConfirmation from '../Account/EmailConfirmation/index';
import Forgot from '../Account/Forgot/index';
import LogIn from '../Account/Login/index';
import Reset from '../Account/Reset/index';
import AccountList from '../Account/index';
import AccountPage from '../Account/AccountPage/index';
import AccountEdit from '../Account/AccountEdit/index';
import AccountCreate from '../Account/AccountCreate/index';
import AccountApproveList from '../Account/AccountApproveList/index';
import ContentList from '../Content/index';
import ContentPage from '../Content/ContentPage/index';
import ContentEdit from '../Content/ContentEdit/index';
import ContentCreate from '../Content/ContentCreate/index';
import TransactionList from '../Transaction/index';
import TransactionPage from '../Transaction/TransactionPage/index';
import DeviceList from '../Device/index';
import DevicePage from '../Device/DevicePage/index';
import DeviceEdit from '../Device/DeviceEdit/index';
import DeviceCreate from '../Device/DeviceCreate/index';
import DeviceStatusList from '../DeviceStatus/index';
import DeviceStatusPage from '../DeviceStatus/DeviceStatusPage/index';
import DeviceStatusEdit from '../DeviceStatus/DeviceStatusEdit/index';
import DeviceStatusCreate from '../DeviceStatus/DeviceStatusCreate/index';
import DeviceStatusLogList from '../DeviceStatusLog/index';
import DeviceCommandList from '../DeviceCommand/index';
import DeviceCommandCreate from '../DeviceCommand/DeviceCommandCreate/index';
import LiveList from '../Live/index';
import { useRole } from '../../shared/utility/APIUtils';
import { getHomePage } from '../../shared/utility/RoleHelper';

const Account = () => (
  <Switch>
    <Route exact path="/accounts" component={AccountList} />
    <Route path="/accounts/show/:id" component={AccountPage} />
    <Route path="/accounts/edit/:id" component={AccountEdit} />
    <Route path="/accounts/create" component={AccountCreate} />
    <Route path="/accounts/approve-list" component={AccountApproveList} />
  </Switch>
);

const Content = () => (
  <Switch>
    <Route exact path="/contents" component={ContentList} />
    <Route path="/contents/show/:id" component={ContentPage} />
    <Route path="/contents/edit/:id" component={ContentEdit} />
    <Route path="/contents/create/:menuItemId?" component={ContentCreate} />
  </Switch>
);

const Device = () => (
  <Switch>
    <Route exact path="/devices" component={DeviceList} />
    <Route path="/devices/show/:id" component={DevicePage} />
    <Route path="/devices/edit/:id" component={DeviceEdit} />
    <Route path="/devices/create" component={DeviceCreate} />
  </Switch>
);

const DeviceStatus = () => (
  <Switch>
    <Route exact path="/device-statuses" component={DeviceStatusList} />
    <Route path="/device-statuses/show/:id" component={DeviceStatusPage} />
    <Route path="/device-statuses/edit/:id" component={DeviceStatusEdit} />
    <Route path="/device-statuses/create" component={DeviceStatusCreate} />
  </Switch>
);

const DeviceStatusLog = () => (
  <Switch>
    <Route exact path="/device-status-logs" component={DeviceStatusLogList} />
  </Switch>
);

const DeviceCommand = () => (
  <Switch>
    <Route exact path="/device-commands" component={DeviceCommandList} />
    <Route path="/device-commands/create" component={DeviceCommandCreate} />
  </Switch>
);

const Transaction = () => (
  <Switch>
    <Route exact path="/transactions" component={TransactionList} />
    <Route path="/transactions/show/:id" component={TransactionPage} />
  </Switch>
);

const Live = () => (
  <Switch>
    <Route exact path="/live" component={LiveList} />
  </Switch>
);

const WrappedRoutes = () => {
  const homepage = getHomePage(useRole());
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route
          exact
          path="/"
          render={() => <Redirect replace to={homepage} />}
        />
        <Route path="/accounts" component={Account} />
        <Route path="/contents" component={Content} />
        <Route path="/devices" component={Device} />
        <Route path="/device-statuses" component={DeviceStatus} />
        <Route path="/device-status-logs" component={DeviceStatusLog} />
        <Route path="/device-commands" component={DeviceCommand} />
        <Route path="/transactions" component={Transaction} />
        <Route path="/live" component={Live} />
      </div>
    </div>
  );
};

const Router = () => (
  <MainWrapper>
    <main>
      <div className="alertbar">
        <Alert />
      </div>
      <Spinner />
      <Switch>
        <Route exact path="/email_confirmation" component={EmailConfirmation} />
        <Route exact path="/forgot" component={Forgot} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/reset" component={Reset} />
        <PrivateRoute path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
