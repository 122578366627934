import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Aux from '../hoc/Auxiliary';
import Backdrop from './Backdrop';

const Spinner = ({ spinnerIsVisible }) => (spinnerIsVisible
  ? (
    <Aux>
      <Backdrop />
      <div className="spinner__modal">
        <div className="spinner__animation">Loading...</div>
      </div>
    </Aux>
  ) : null);

Spinner.propTypes = {
  spinnerIsVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  spinnerIsVisible: state.spinner.spinnerIsVisible,
});

export default connect(mapStateToProps)(Spinner);
