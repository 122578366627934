import React, { useState, useEffect } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Container, Row } from 'reactstrap';
import EmailConfirmationCard from './components/EmailConfirmationCard';
import LinkErrorCard from '../../../shared/components/LinkErrorCard';

const EmailConfirmation = () => {
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const confirmation = async () => {
      const query = queryString.parse(window.location.search);
      const path = `${process.env.REACT_APP_TICKET_API_URL}/api/auth/confirm?confirmation_token=${query.confirmation_token}`;
      await axios.get(path)
        .then(() => {
          setConfirmed(true);
        }).catch(() => {
          setError(true);
        });
    };
    confirmation();
  }, []);

  return (
    <Container>
      <Row>
        {confirmed ? <EmailConfirmationCard /> : ''}
        {error ? <LinkErrorCard /> : ''}
      </Row>
    </Container>
  );
};

export default EmailConfirmation;
