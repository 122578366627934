import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PageHandler from '../../../shared/components/PageHandler';
import MaterialTable from '../../../shared/components/table/MaterialTable';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { getFrontendRole } from '../../../shared/utility/RoleHelper';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import ConfirmPopup from '../../../shared/components/ConfirmPopup';

const AccountApproveList = ({ t }) => {
  const uri = `${process.env.REACT_APP_TICKET_API_URL}/api`;
  const isAuthenticated = useAuthCheck();
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const fetchData = useCallback(async () => {
    onShowSpinner();
    const path = `${uri}/users${window.location.search || '?'}&confirmed=1&enabled=0`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setRows(res.data.list.map((row) => {
          const newRow = { ...row };
          if (Array.isArray(row.roles) && row.roles.length > 0) {
            newRow.roles = getFrontendRole(row.roles[0]);
          }
          return newRow;
        }));
        setTotal(res.data.totalCount);
      }).catch((err) => {
        setStatusCode(err.response.status);
        setError(true);
      }).finally(() => {
        onHideSpinner();
      });
  }, [onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      fetchData();
    }
  }, [isAuthenticated, fetchData]);

  const updateData = useCallback(async (id) => {
    onShowSpinner();
    const path = `${uri}/users/${id}?mail=1`;
    await axios.put(path, { enabled: true })
      .then(() => {
        fetchData();
      }).catch(() => {
        setError(true);
        onHideSpinner();
      });
  }, [fetchData, onHideSpinner, onShowSpinner]);

  const showDeleteConfirmation = (id) => {
    const item = rows.find((row) => row.id === id);
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const onCancelDelete = () => {
    setIsModalOpen(false);
  };

  const onConfirmDelete = useCallback(async () => {
    setIsModalOpen(false);
    onShowSpinner();
    const path = `${uri}/users/${itemToDelete.id}?mail=1`;
    await axios.delete(path)
      .then(() => {
        fetchData();
      }).catch(() => {
        setError(true);
        onHideSpinner();
      });
  }, [fetchData, itemToDelete, onHideSpinner, onShowSpinner]);

  const actionCallbacks = {
    update: updateData,
    delete: showDeleteConfirmation,
  };

  const columns = [
    {
      id: 'first_name', label: t('account:firstName'),
    },
    {
      id: 'last_name', label: t('account:lastName'),
    },
    {
      id: 'email', label: t('account:email'),
    },
    {
      id: 'company_name', label: t('account:company'),
    },
    {
      id: 'roles', label: t('account:role'),
    },
  ];

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <ConfirmPopup
          isOpen={isModalOpen}
          item={itemToDelete && itemToDelete.name}
          onCancel={onCancelDelete}
          onConfirm={onConfirmDelete}
        />
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('account:approveList')}</h3>
          </Col>
        </Row>
        <Row>
          <MaterialTable
            rows={rows}
            columns={columns}
            total={total}
            callback={fetchData}
            actionCallbacks={actionCallbacks}
            operations={['accept', 'delete']}
          />
          {error ? <div>{t('common:error')}</div> : ''}
        </Row>
      </Container>
    </PageHandler>
  );
};

AccountApproveList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AccountApproveList);
