const rules = {
  ROLE_ADMIN: {
    static: [
      'accounts',
      'cms',
      'settingsDropdown',
      'systemListItems',
      'eventsDropdown',
      'productions',
      'events',
      'locations',
      'ticketTypes',
      'transactions',
      'deviceStatusLogs',
      'deviceStatuses',
      'allpayDropdown',
      'devices',
      'deviceCommands',
      'playlists',
      'live',
    ],
  },
  ROLE_PROMOTER: {
    static: [
      'eventsDropdown',
      'productions',
      'events',
    ],
  },
  ROLE_CUSTOMER: {
    static: [
      'eventsDropdown',
      'productions:show',
      'events:show',
    ],
  },
};

export default rules;
