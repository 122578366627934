import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const TransactionCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="transaction-card">
            <Row>
              <Col md={12} lg={12}>
                <div className="transaction-card__info">
                  {(data?.transaction?.first_name && data?.transaction?.last_name) && (
                    <Row>
                      <Col md={2}>
                        <p>{t('transaction:customerName')}</p>
                        <span>{`${data?.transaction?.first_name} ${data?.transaction?.last_name}`}</span>
                      </Col>
                    </Row>
                  )}
                  {(data?.transaction?.customer_email) && (
                    <Row>
                      <Col md={2}>
                        <p>{t('transaction:customerEmail')}</p>
                        <span>{data?.transaction?.customer_email}</span>
                      </Col>
                    </Row>
                  )}
                  {(data?.transaction?.customer_phone_number) && (
                    <Row>
                      <Col md={2}>
                        <p>{t('transaction:customerPhoneNumber')}</p>
                        <span>{data?.transaction?.customer_phone_number}</span>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={2}>
                      <p>{t('transaction:paymentType')}</p>
                      <span>{data?.transaction?.payment_type}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('transaction:value')}</p>
                      <span>{data?.transaction?.value}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('transaction:paidAmount')}</p>
                      <span>{data?.transaction?.paid_amount}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <p>{t('transaction:changedue')}</p>
                      <span>{data?.transaction?.changedue}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

TransactionCard.propTypes = {
  data: PropTypes.shape({
    transaction: PropTypes.shape({
      paid_amount: PropTypes.number,
      payment_type: PropTypes.string,
      value: PropTypes.number,
      changedue: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      customer_email: PropTypes.string,
      customer_phone_number: PropTypes.string,
    }),
    ej_data: PropTypes.string,
  }).isRequired,
};

export default TransactionCard;
