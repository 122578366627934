import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AccountEditCard from './components/AccountEditCard';
import { useAuthCheck } from '../../../shared/utility/APIUtils';
import { showSpinner, hideSpinner } from '../../../redux/actions/spinnerActions';
import PageHandler from '../../../shared/components/PageHandler';

const AccountEdit = () => {
  const { id } = useParams();
  const isAuthenticated = useAuthCheck();
  const [account, setAccount] = useState({});
  const [statusCode, setStatusCode] = useState(null);

  const dispatch = useDispatch();
  const onShowSpinner = useCallback(() => dispatch(showSpinner()), [dispatch]);
  const onHideSpinner = useCallback(() => dispatch(hideSpinner()), [dispatch]);

  const loadAccount = useCallback(async () => {
    onShowSpinner();
    const path = `${process.env.REACT_APP_TICKET_API_URL}/api/users/${id}`;
    await axios.get(path)
      .then((res) => {
        setStatusCode(res.status);
        setAccount(res.data);
        onHideSpinner();
      }).catch((err) => {
        setStatusCode(err.response.status);
        onHideSpinner();
      });
  }, [id, onHideSpinner, onShowSpinner]);

  useEffect(() => {
    if (isAuthenticated === true) {
      loadAccount();
    }
  }, [isAuthenticated, loadAccount]);

  return (
    <PageHandler statusCode={statusCode}>
      <Container>
        <div className="account-edit">
          <Row>
            <Col md={12}>
              <h3 className="page-title">Edit User</h3>
            </Col>
          </Row>
          <Row>
            <AccountEditCard data={account} />
          </Row>
        </div>
      </Container>
    </PageHandler>
  );
};

export default AccountEdit;
