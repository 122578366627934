import { SET_LOCATION_LIST } from '../actions/productionActions';

const initialState = {
  locationList: [],
};

export default (state = initialState, action) => {
  if (action.type === SET_LOCATION_LIST) {
    return {
      ...state,
      locationList: action.payload,
    };
  }
  return state;
};
