import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Forbidden403 from './defaultPages/Forbidden403';
import NotFound404 from './defaultPages/NotFound404';

const PageHandler = ({ children, statusCode }) => {
  const { t } = useTranslation();

  switch (statusCode) {
    case 500:
      return <div>{t('common:error')}</div>;
    case 404:
      return <NotFound404 />;
    case 403:
      return <Forbidden403 />;
    case 200:
      return children;
    default:
      return '';
  }
};

PageHandler.propTypes = {
  children: PropTypes.element.isRequired,
  statusCode: PropTypes.number,
};

PageHandler.defaultProps = {
  statusCode: null,
};

export default PageHandler;
