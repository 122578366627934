import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import Can from '../../../shared/authorization/Can';

const OneTestIcon = `${process.env.PUBLIC_URL}/img/OneTestIcon.svg`;

const SidebarContent = ({ onClick, t }) => {
  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title={t('menu:users')} route="/accounts" onClick={hideSidebar} />
        <Can
          perform="allpayDropdown"
          yes={() => (
            <SidebarCategory title={t('menu:allpay:title')} icon={OneTestIcon}>
              <Can
                perform="devices"
                yes={() => (
                  <SidebarLink title={t('menu:allpay:devices')} route="/devices" onClick={hideSidebar} />
                )}
              />
              <Can
                perform="deviceStatuses"
                yes={() => (
                  <SidebarLink title={t('menu:allpay:deviceStatuses')} route="/device-statuses" onClick={hideSidebar} />
                )}
              />
              <Can
                perform="deviceStatusLogs"
                yes={() => (
                  <SidebarLink title={t('menu:allpay:deviceStatusLogs')} route="/device-status-logs" onClick={hideSidebar} />
                )}
              />
              <Can
                perform="transactions"
                yes={() => (
                  <SidebarLink title={t('menu:allpay:transactions')} route="/transactions" onClick={hideSidebar} />
                )}
              />
              <Can
                perform="deviceCommands"
                yes={() => (
                  <SidebarLink title={t('menu:allpay:commands')} route="/device-commands" onClick={hideSidebar} />
                )}
              />
              <Can
                perform="live"
                yes={() => (
                  <SidebarLink title={t('menu:allpay:live')} route="/live" onClick={hideSidebar} />
                )}
              />
            </SidebarCategory>
          )}
        />
        <SidebarLink title={t('menu:cms:contents')} route="/contents" onClick={hideSidebar} />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withTranslation('menu')(SidebarContent);
