import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import AccountFilterForm from './AccountFilterForm';

const AccountFilterCard = ({ refreshCallback }) => {
  const date = () => {
    const urlList = {};

    window.location.search
      .slice(1)
      .split('&')
      .forEach((item) => {
        const [key, val] = item.split('=');
        urlList[key] = decodeURIComponent(val);
      });

    return urlList;
  };

  return (
    <Col md={12} lg={12}>
      <AccountFilterForm
        refreshCallback={refreshCallback}
        initialValues={date()}
      />
    </Col>
  );
};

AccountFilterCard.propTypes = {
  refreshCallback: PropTypes.func.isRequired,
};

export default AccountFilterCard;
