import React from 'react';

import { Card, CardBody, Col } from 'reactstrap';
import DeviceCommandCreateForm from './DeviceCommandCreateForm';

const DeviceCommandCreateCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <DeviceCommandCreateForm onSubmit />
      </CardBody>
    </Card>
  </Col>
);

export default DeviceCommandCreateCard;
